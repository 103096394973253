import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import { SearchIcon } from 'components/elements/Icons/SearchIcon'

type SearchInputGroupProps = {
  onChange: (text: string) => void
}

export function SearchInputGroup({ onChange }: SearchInputGroupProps) {
  const { t } = useTranslation('common')

  return (
    <InputGroup>
      <Input
        pl={14}
        fontSize="md"
        placeholder={t('filter-search-input-placeholder')}
        backgroundColor="secondary.grey.100"
        border="none"
        onChange={(event) => onChange(event.target.value)}
      />
      <InputLeftElement>
        <SearchIcon boxSize={4} />
      </InputLeftElement>
    </InputGroup>
  )
}
