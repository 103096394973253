export function normalizeQuery(query: string): string {
  return (
    query
      // Remove comments
      .replace(/#.*/g, '')
      // Replace new lines with spaces
      .replace(/\r?\n/g, ' ')
      // Replace multiple spaces with one space
      .replace(/ +/g, ' ')
      // Remove spaces before and after brackets
      .replace(/ +{ +/g, '{')
      .replace(/ *} +/g, '}')
      // Remove spaces before and after commas
      .replace(/([,:]) +/g, '$1')
  )
}
