import { OrderSetCustomFieldAction, OrderSetCustomerIdAction } from '@commercetools/platform-sdk'

export const setOrderCustomerId = (customerId: string): OrderSetCustomerIdAction => ({
  action: 'setCustomerId',
  customerId,
})

export const setOrderPurchaseDocumentId = (value: string): OrderSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'purchaseOrderDocumentId',
  value,
})

export const setOrderPurchaseDocumentName = (value: string): OrderSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'purchaseOrderDocumentName',
  value,
})
