import { Translate } from 'next-translate'

export function getOrderStatusText(orderState: string, t: Translate): string {
  switch (orderState) {
    case 'Open':
      return t('order-state-open')
    case 'Confirmed':
      return t('order-state-confirmed')
    case 'Complete':
      return t('order-state-complete')
    case 'Cancelled':
      return t('order-state-cancelled')
    default:
      return t('order-state-unknown')
  }
}
